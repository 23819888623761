<div class="DTX_CONTENT_DOCUMENT">
    <a class="DTX_CONTENT_DOCUMENT_HEADER_LINK" [download]="getDocumentDoesDownload()" href="{{ getDocumentUrl() }}">
        <h4 class="DTX_CONTENT_DOCUMENT_TITLE">{{ documents.DisplayName }}</h4>
    </a>
    <div class="DTX_CONTENT_DOCUMENT_BUTTON">
        <a class="btn btn-warning DTX_CONTENT_DOCUMENT_BUTTON_LINK" [download]="getDocumentDoesDownload()" href="{{ getDocumentUrl() }}">
            <i aria-hidden="true" class="{{ getDocumentImage() }} DTX_CONTENT_DOCUMENT_BUTTON_ICON"></i>
            <i aria-hidden="true" class="fa fa-download DTX_CONTENT_DOCUMENT_BUTTON_ICON"></i>
        </a>
    </div>
</div>

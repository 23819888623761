<header class="DTX_HEADER" [ngClass]="{ DTX_HEADER_TRANSPARENT: hasBanner && type !== 'Hamburger' }" appStickyHeader [threshold]="250">
    <div class="DTX_HEADER_MAIN container-fluid {{ headerContent.CssClasses }}">
        <div class="DTX_HEADER_LEFT">
            @if (headerContent.Logo) {
                <app-images [Image]="headerContent.Logo" [SqWidth]="1200" class="DTX_HEADER_LOGO" [urlOverride]="getSiteHome()" />
            }
        </div>
        @if (type !== 'Hamburger' && !showSecondaryNav) {
            <div class="tw-grow tw-shrink">
                <app-header-nav [menu]="menuList" />
            </div>
        }
        <div class="DTX_HEADER_RIGHT">
            <app-language class="DTX_HEADER_LANGUAGE" />
            <app-menu [menuList]="menuList" [showLogonButton]="$showLogonButton()" class="DTX_HEADER_MENU" />
        </div>
    </div>
    @if (type !== 'Hamburger' && showSecondaryNav) {
        <div class="DTX_HEADER_SECONDARY">
            <app-header-nav [menu]="menuList" styleClass="!tw-justify-end" [dynamic]="false" />
        </div>
    }
</header>
@for (html of headerContent.EmbeddedHtml; track html) {
    <app-embed-html [embedHtml]="html" class="DTX_HEADER_HTML" />
}
@for (banners of headerContent.Banner; track banners) {
    <app-banner [banners]="banners" class="DTX_HEADER_BANNER" />
}

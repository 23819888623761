import { CommonModule } from '@angular/common';
import { afterNextRender, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MenuItem as PMenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { HeaderNavItemComponent } from '../header-nav-item/header-nav-item.component';

@Component({
  selector: 'app-header-nav',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, TieredMenuModule, MenubarModule, HeaderNavItemComponent],
  templateUrl: './header-nav.component.html',
  styleUrl: './header-nav.component.scss',
})
export class HeaderNavComponent implements OnChanges {
  @Input()
  menu!: Menu;
  @Input()
  itemWidth?: number;
  @Input()
  itemGap = 48;
  @Input()
  styleClass: string = '';
  @Input()
  dynamic = true;
  @Input()
  dropdown = true;

  displayCount = 0;
  allItems: PMenuItem[] = [];
  displayItems: PMenuItem[] = [];

  @ViewChild('navContainer')
  navContainer!: ElementRef<HTMLDivElement>;

  constructor() {
    afterNextRender(() => {
      setTimeout(this.onResize);
    });
  }

  updateDisplayItems() {
    // Set the display count based on the width of the container, 60 percent of the window width max
    const w = Math.min(this.navContainer.nativeElement.clientWidth, window.innerWidth * 0.6);
    let newCount = 0;
    if (this.dynamic) {
      newCount = this.calcCountFromWidth(w);
    } else {
      newCount = this.menu.MenuItems.length;
    }

    if (newCount !== this.displayCount) {
      this.displayCount = newCount;
      if (newCount >= this.allItems.length) {
        this.displayItems = this.allItems;
      } else if (newCount > 0) {
        this.displayItems = this.allItems.slice(0, newCount - 1);
        this.displayItems.push({
          label: 'More',
          items: this.allItems.slice(newCount - 1),
        });
      }
    }
  }
  calcCountFromWidth(width: number) {
    let s = 0;
    for (let i = 0; i < this.allItems.length; i++) {
      s += Math.max((this.allItems[i].label?.length || 0) * 12 + 4, 52);
      s += this.itemGap;
      if (s > width) return i;
    }
    return this.allItems.length;
  }

  onResize = () => {
    this.updateDisplayItems();
  };

  buildMenuItems(menuItems: MenuItem[]): PMenuItem[] {
    if (!menuItems?.length) return [];
    const res = [];
    for (const item of menuItems) {
      let routerLink = item.Slug;
      if (typeof routerLink === 'string' && !routerLink.startsWith('/')) routerLink = `/${routerLink}`;
      const menuItem: PMenuItem = {
        label: item.Text ?? '',
        routerLink: routerLink ?? '',
        items: item.Children ? this.buildMenuItems(item.Children) : undefined,
      };
      res.push(menuItem);
    }
    return res;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['menu']) {
      this.allItems = this.buildMenuItems(this.menu.MenuItems);
    }
  }
}

import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { EmbedPipe } from '../../pipes/embed-pipe';
import { AnchorToRouterService } from '../../services/anchor-to-router.service';

@Component({
  selector: 'app-embed-html',
  templateUrl: './embed-html.component.html',
  styleUrls: ['./embed-html.component.scss'],
  standalone: true,
  imports: [EmbedPipe],
})
export class EmbedHtmlComponent implements AfterViewInit {
  @Input() embedHtml: any;

  constructor(
    private el: ElementRef,
    private anchorToRouterService: AnchorToRouterService,
  ) {}

  ngAfterViewInit() {
    this.anchorToRouterService.convertAnchorsToRouterLinks(this.el);
  }

  getEmbedHtml() {
    let embed: any;
    try {
      embed = this.embedHtml.Embed;
      return embed;
    } catch (e) {
      console.log(e);
    }
  }
}

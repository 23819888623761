<div
    [ngStyle]="{ 'width.px': itemWidth, 'padding-left.px': itemGap / 2, 'padding-right.px': itemGap / 2 }"
    class="DTX_HEADER_NAV_ITEM"
    #itemEl
>
    <div>
        <a [routerLink]="item.routerLink" class="DTX_HEADER_NAV_LINK" routerLinkActive="active">{{ item.label }}</a>
    </div>
    @if (item.items?.length) {
        <p-tieredMenu
            [model]="item.items"
            class="DTX_HEADER_NAV_SUBMENU"
            [ngStyle]="{ 'width.px': menuWidth }"
            [ngClass]="{ 'menu-rtl': rtl, show: show }"
        >
            <ng-template pTemplate="item" let-item let-hasSubmenu="hasSubmenu">
                @if (!item.items?.length) {
                    <a [routerLink]="item.routerLink" [href]="item.href" class="p-menuitem-link" (click)="hideMenu()">{{ item.label }}</a>
                } @else {
                    <div class="p-menuitem-link">
                        @if (rtl) {
                            <span class="fa fa-chevron-left tw-mr-4"></span>
                        }
                        <a [routerLink]="item.routerLink" [href]="item.href" class="tw-mr-auto">{{ item.label }}</a>
                        @if (!rtl) {
                            <span class="fa fa-chevron-right tw-ml-2"></span>
                        }
                    </div>
                }
            </ng-template>
        </p-tieredMenu>
    }
    <!-- } -->
</div>

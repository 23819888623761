import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MenuItem as PMenuItem } from 'primeng/api';
import { TieredMenuModule } from 'primeng/tieredmenu';

@Component({
  selector: 'app-header-nav-item',
  standalone: true,
  imports: [CommonModule, TieredMenuModule, RouterLink],
  templateUrl: './header-nav-item.component.html',
  styleUrl: './header-nav-item.component.scss',
})
export class HeaderNavItemComponent implements AfterViewInit {
  @Input()
  item!: PMenuItem;

  @Input()
  itemWidth?: number;
  @Input()
  itemGap: number = 48;
  @Input()
  menuWidth?: number = 168;

  @ViewChild('itemEl')
  itemEl!: ElementRef<HTMLDivElement>;

  rtl = false;
  show = false;

  ngAfterViewInit() {
    if (typeof window === 'undefined') return;
    const offsetRight = window.innerWidth - this.itemEl.nativeElement.offsetLeft - this.itemEl.nativeElement.offsetWidth;
    if (offsetRight < (this.menuWidth ?? 168)) this.rtl = true;
  }

  onOutsideClickEventListener = (event: Event) => {
    if (this.itemEl?.nativeElement.contains(event.target as Node)) return;
    this.hideMenu();
  };
  showMenu = () => {
    this.show = true;
  };
  hideMenu = () => {
    this.show = false;
  };
  toggleMenu = () => {
    this.show = !this.show;
  };
}
